<script setup lang="ts">
import { computed } from 'vue';

import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';

interface ItemListTagsProps {
  row: any;
}

const props = defineProps<ItemListTagsProps>();

const formattedItemTypes = computed(() => {
  const formatString = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  return props.row.itemType.map((type: any) => formatString(type.name)).join(', ');
});
</script>

<template>
  <BaseFlexbox
    class="px-1 py-3 w-60"
    alignItems="center"
  >
    <span>{{ formattedItemTypes }}</span>
  </BaseFlexbox>
</template>
