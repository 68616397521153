<script setup lang="ts">
import { computed, type Component } from 'vue';
import { uniqueId } from '@/common/uniqueId';
import { useInputClasses } from '@/components/shared/DeskInput/useInputClasses';
import DeskLabel from '@/components/shared/DeskLabel/DeskLabel.vue';
import DeskInputIcon from './DeskInputIcon.vue';
import type { InputType } from './types';

interface DeskInputProps {
  label?: string;
  disabled?: boolean;
  required?: boolean;
  // A lot of our schema fields have a type like this `name?: string | null`
  modelValue?: number | string | null;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  type?: InputType;
  inputStyles?: string;
  autocomplete?: string;
  step?: string;
  icon?: Component;
  dataTestId?: string;
}

const props = withDefaults(defineProps<DeskInputProps>(), {
  type: 'text',
});

const id = uniqueId('desk-input');

const emit = defineEmits<{
  'update:modelValue': [value: number | string | null | undefined];
  input: [value: number | string | null | undefined];
  blur: [value: number | string | null | undefined];
}>();

const { getInputClasses } = useInputClasses();
const inputClasses = computed(() => getInputClasses(props));

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

const onBlur = () => {
  emit('blur', model.value);
};
</script>

<template>
  <div class="w-full">
    <DeskLabel v-if="label" :label="label" :for="id" />
    <div class="flex relative">
      <DeskInputIcon v-if="icon" :icon="icon" />
      <input
        v-model="model"
        :id="id"
        :class="inputClasses"
        :type="type"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        @blur="onBlur"
        :step="step"
        :data-test-id="dataTestId || 'desk-input'"
      />
      <div v-if="$slots.suffix" class="absolute right-2.5 bottom-2.5">
        <slot name="suffix" />
      </div>
    </div>
    <span v-if="error && errorMessage" class="text-sm text-red-500">{{
      errorMessage
    }}</span>
  </div>
</template>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
