import { OfferedSinceOptionVariant } from '@/components/itemList/types';
import type { OptionsType } from '@/components/shared/DeskSelect/types';

export enum SortByOption {
  NEWEST = 1,
  OLDEST,
  ITEM_NAME_ASCENDING,
  ITEM_NAME_DESCENDING,
  COMPANY_SHORTNAME_ASCENDING,
  COMPANY_SHORTNAME_DESCENDING,
  CITY_NAME_ASCENDING,
  CITY_NAME_DESCENDING,
  QUALITY_ASCENDING,
  QUALITY_DESCENDING,
  // RATING,
}

export const SORT_BY_OPTIONS: Array<OptionsType> = [
  {
    id: 'newest',
    name: 'Newest',
    value: SortByOption.NEWEST,
  },
  {
    id: 'oldest',
    name: 'Oldest',
    value: SortByOption.OLDEST,
  },
  {
    id: 'itemNameAscending',
    name: 'Item name (A-Z)',
    value: SortByOption.ITEM_NAME_ASCENDING,
  },
  {
    id: 'itemNameDescending',
    name: 'Item name (Z-A)',
    value: SortByOption.ITEM_NAME_DESCENDING,
  },
  {
    id: 'companyNameAscending',
    name: 'Company name (A-Z)',
    value: SortByOption.COMPANY_SHORTNAME_ASCENDING,
  },
  {
    id: 'companyNameDescending',
    name: 'Company name (Z-A)',
    value: SortByOption.COMPANY_SHORTNAME_DESCENDING,
  },
  {
    id: 'qualityAscending',
    name: 'Quality (Low to High)',
    value: SortByOption.QUALITY_ASCENDING,
  },
  {
    id: 'qualityDescending',
    name: 'Quality (High to Low)',
    value: SortByOption.QUALITY_DESCENDING,
  },
];

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_SORT_BY = SortByOption.QUALITY_DESCENDING;
export const DEFAULT_OFFERED_SINCE_VARIANT = OfferedSinceOptionVariant.NO_PREFERENCE;

export enum QueryParamName {
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH = 'search',
  LOC_SEARCH = 'locSearch',
  SORT_BY = 'sortBy',
  TAGS = 'tags',
  OFFERED_SINCE = 'offeredSince',
  CURRENCIES = 'currencies',
  INCLUDE_PHOTOS = 'includePhotos',
}

export const PAGE_SIZE_OPTIONS: OptionsType[] = [
  { id: '30', name: '30', value: 30 },
  { id: '50', name: '50', value: 50 },
  { id: '100', name: '100', value: 100 },
];
