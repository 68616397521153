/**
 * TODO: Make these refer to the schemas generated by openapi-typescript package.
 * For now just define these manually.
 */
import type { RouteLocationNormalized } from 'vue-router';

export type ErrorResponse = any;
export type ValidationErrorResponse = any;

export type TokenPostPayload = {
  username: string;
  password: string;
};

export type TokenPayload = {
  refresh: string;
  access: string;
};

export type TokenRefreshPostPayload = {
  refresh: string;
};

export type TokenRefreshPayload = {
  access: string;
};

export interface PaginatedListPayload<T> {
  count: number;
  next: string;
  previous: string;
  results: Array<T>;
}

export type Location = {
  id: number;
  city: string;
  province: string;
  country: string;
};

export type CompanyPayload = {
  id: number;
  company_url: string | null;
  name: string;
  shortname: string;
  language: string;
  timezone: string;
  about: string;
  image_url: string;
  created_at: string;
  modified_at: string;
  primary_location: Location;
  image_logo_url: string;
  currency: string;
};

export type CompanyListPayload = PaginatedListPayload<CompanyPayload>;

export type Company = {
  id: number;
  companyUrl: string | null;
  companyImage: string | null;
  name: string;
  shortName: string;
  language: string;
  timezone: string;
  about: string;
  imageUrl: string;
  createdAt: string | null;
  modifiedAt: string | null;
  primaryLocation: Location;
  currency: string;
};

export type ImagePayload = {
  created_at: string;
  id: string;
  image_url: string;
  item_id: number;
  modified_at: string;
};

export type StructuredDescriptionPayload = {
  id: number;
  duration: string;
  cancellation_summary: string;
  meeting_point: string;
  min_age?: number | null;
  max_age?: number | null;
  group_size?: number | null;
  accessibility?: string | null;
  pricing?: string | null;
  description: string;
  description_safe_html: string | null;
  what_is_included: string;
  what_is_included_safe_html: string | null;
  what_is_not_included: string;
  what_is_not_included_safe_html: string | null;
  itinerary: string;
  itinerary_safe_html: string | null;
  highlights: string;
  highlights_safe_html: string | null;
  check_in_details: string;
  check_in_details_safe_html: string | null;
  what_to_bring: string;
  what_to_bring_safe_html: string | null;
  special_requirements: string;
  special_requirements_safe_html: string | null;
  restrictions?: string | null;
  restrictions_safe_html?: string | null;
  extras: string;
  extras_safe_html: string | null;
  disclaimers: string;
  disclaimers_safe_html: string | null;
  faqs?: string | null;
  faqs_safe_html?: string | null;
};

export type StructuredDescription = {
  id: number;
  duration: string;
  cancellationSummary: string;
  meetingPoint: string;
  minAge?: number | null;
  maxAge?: number | null;
  groupSize?: number | null;
  accessibility?: string | null;
  pricing?: string | null;
  description: string;
  descriptionSafeHtml?: string | null;
  whatIsIncluded: string;
  whatIsIncludedSafeHtml?: string | null;
  whatIsNotIncluded: string;
  whatIsNotIncludedSafeHtml?: string | null;
  itinerary: string;
  itinerarySafeHtml?: string | null;
  highlights: string;
  highlightsSafeHtml?: string | null;
  checkInDetails: string;
  checkInDetailsSafeHtml?: string | null;
  whatToBring: string;
  whatToBringSafeHtml?: string | null;
  specialRequirements: string;
  specialRequirementsSafeHtml?: string | null;
  restrictions?: string | null;
  restrictionsSafeHtml?: string | null;
  extras: string;
  extrasSafeHtml?: string | null;
  disclaimers: string;
  disclaimersSafeHtml?: string | null;
  faqs?: string | null;
  faqsSafeHtml?: string | null;
};

export type ItemPayload = {
  id: number;
  name: string;
  short_name: string;
  headline: string;
  location: Location;
  company_id: number;
  company: CompanyPayload;
  main_image_url: string;
  tags: Array<TagBasic>;
  created_at: string;
  modified_at: string;
  image_count: number;
  quality_score: number;
  company_item_count: number;
  description: string;
  description_safe_html: string | null;
  structured_description?: StructuredDescriptionPayload | null;
  is_bookmarked: boolean;
};

export type ItemListPayload = PaginatedListPayload<ItemPayload>;

export type ItemRatingPayload = {
  provider: 'tripadvisor' | 'google_reviews';
  rating: number;
  review_count: number;
};

export enum ItemRatingProvider {
  GOOGLE_REVIEWS,
  TRIP_ADVISOR,
}

export type ItemRating = {
  provider: ItemRatingProvider;
  rating: number;
  reviewCount: number;
};

export type Item = {
  itemId: number;
  name: string;
  shortName: string;
  headline: string;
  location: Location;
  companyId: number;
  company: Company;
  mainImageUrl: string;
  tags: Array<TagBasic>;
  createdAt: string | null;
  modifiedAt: string | null;
  extraImageCount: number;
  qualityScore: number;
  companyItemCount: number;
  description: string;
  descriptionSafeHtml?: string | null;
  structuredDescription?: StructuredDescription | null;
  rating: ItemRating | null;
  isBookmarked: boolean;
};

export type TagPayload = {
  name: string;
  shortname: string;
  count: number;
};

export type TagListPayload = PaginatedListPayload<TagPayload>;

export type TagBasic = {
  name: string;
  shortname: string;
  id: number;
};

export type Tag = {
  name: string;
  shortname: string;
  relatedItemsCount: number;
};

export type CurrencyPayload = {
  name: string;
  code: string;
  symbol: string;
  related_items_count: number;
};

export type CurrencyListPayload = PaginatedListPayload<CurrencyPayload>;

export type Currency = {
  name: string;
  code: string;
  symbol: string;
  relatedItemsCount: number;
};

export type PagedItemsMap = Map<number, Array<Item>>;

export interface LoginOptions {
  next?: RouteLocationNormalized;
}

export type LocationPayload = {
  city: string;
  province: string;
  country: string;
};

export type LocationListPayload = Array<LocationPayload>;

export type MatchedFieldType = 'city' | 'province' | 'country';

export interface LocationSuggestion {
  city: string;
  province: string;
  country: string;
  matchedFields: MatchedFieldType[];
  locationType: number;
}

type UserPayload = {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_active: boolean;
  in_fhdn: boolean;
  in_fhdn_api: boolean;
};

type MinimalCompanyPayload = {
  id: number;
  name: string;
  shortname: string;
  company_url: string | null;
  currency: string;
};

export type MarketplaceUserPayload = {
  user: UserPayload;
  company: MinimalCompanyPayload;
  in_fhdn: boolean;
  in_fhdn_api: boolean;
  created_at: string;
  modified_at: string;
};

type User = {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  isStaff: boolean;
  isActive: boolean;
};

type MinimalCompany = {
  id: number;
  name: string;
  shortName: string;
  companyUrl: string | null;
  currency: string;
};

export type MarketplaceUser = {
  user: User;
  company: MinimalCompany;
  createdAt: string;
  modifiedAt: string;
  inFhdn: boolean;
  inFhdnApi: boolean;
};
