<script setup lang="ts">
import { computed, useAttrs } from 'vue';
import { uniqueId } from '@/common/uniqueId';
import DeskLabel from '@/components/shared/DeskLabel/DeskLabel.vue';
import type { OptionsType } from '@/components/shared/DeskSelect/types';

interface InputSelectProps {
  modelValue?: string | number;
  label?: string;
  description?: string;
  placeholder?: string;
  options: Array<OptionsType>;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  dataTestId?: string;
}

const props = withDefaults(defineProps<InputSelectProps>(), {
  modelValue: '',
  label: '',
  placeholder: 'Select',
  disabled: false,
});

const emit = defineEmits(['update:modelValue']);
const attributes = useAttrs();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(val: string | number) {
    emit('update:modelValue', val);
  },
});

const id = uniqueId('desk-select');
</script>

<template>
  <div class="w-full">
    <DeskLabel v-if="label" :for="id" :label="label" :description="description" />
    <select v-bind="attributes" v-model="model" :disabled="disabled" :id="id"
      class="border-gray-300 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" :class="{
        'cursor-not-allowed bg-gray-100 text-gray-500': disabled,
        'bg-gray-50 ': !disabled,
        'border-red-500': error,
        'text-gray-500': !modelValue,
        'text-gray-900': modelValue,
      }" :data-test-id="dataTestId || 'desk-select'">
      <option value="" selected disabled>
        {{ placeholder }}
      </option>
      <option v-for="option in options" :key="option.id" :value="option.value">
        {{ option.name }}
      </option>
    </select>
    <span v-if="error" class="text-sm text-red-500">{{ errorMessage }}</span>
  </div>
</template>
