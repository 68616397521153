<script setup lang="ts">
import { toRefs, type Ref, ref } from 'vue';
import type { FlexDirection, FlexContent, FlexItems, Gap } from './types';
import { useFlexboxClasses } from './useFlexboxClasses';

export interface DeskFlexboxProps {
  direction?: FlexDirection;
  gap?: Gap;
  justifyContent?: FlexContent;
  alignItems?: FlexItems;
  alignSelf?: FlexItems | 'auto';
  grow?: '0' | '1';
  display?: 'flex' | 'inline-flex';
  dataTestId?: string;
}

const props = withDefaults(defineProps<DeskFlexboxProps>(), {
  direction: 'row',
  display: 'flex',
  grow: '0',
  gap: '0',
  justifyContent: 'normal',
  alignItems: 'stretch',
  alignSelf: 'auto',
});

const { flexboxClasses } = useFlexboxClasses(toRefs(props));
const containerRef: Ref<HTMLInputElement | undefined> = ref();

defineExpose({ containerRef });
</script>

<template>
  <div :class="flexboxClasses" :data-test-id="dataTestId || 'desk-flexbox'"
    ref="containerRef">
    <slot></slot>
  </div>
</template>
