import type { ItemRating, TagBasic } from '@/types';

export interface ItemListTableInstance {
  itemId: number;
  name: string;
  companyName: string;
  companyShortName: string;
  imageUrl: string;
  city: string;
  region: string;
  countryCode: string;
  extraImagesCount: number;
  itemType: Array<TagBasic>;
  qualityScore: number;
  rating: ItemRating | null;
}

export type ItemListColumnKey = keyof ItemListTableInstance | 'entireItem';

export enum FilterKind {
  ITEM_TYPE,
  OFFERED_SINCE,
  CURRENCY,
  INCLUDE_PHOTOS,
}

export type ItemTypeOption = {
  itemTypeName: string;
  itemTypeShortName: string;
  relatedItemsCount: number;
  checked: boolean;
};

export enum OfferedSinceOptionVariant {
  NO_PREFERENCE = 1,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_90_DAYS,
}

export type OfferedSinceOption = {
  optionName: string;
  id: OfferedSinceOptionVariant;
};

export type CurrencyOption = {
  currency: string;
  checked: boolean;
  relatedItemsCount: number;
};

export type FiltersChangedEventData = { shouldConfirm: boolean };
