import { ref } from 'vue';
import type { TableData } from './types';

export const useSelectableRows = (
  defaultSelectedRows?: Set<string | number>,
  emitSelectedRows?: (x: Set<string | number>) => void,
) => {
  const selectedRows = ref<Set<string | number>>(
    new Set(defaultSelectedRows) ?? new Set(),
  );
  const isSelectAllChecked = (data: TableData) =>
    data.length > 0 && selectedRows.value?.size === data.length;

  const handleSelectAllRows = (
    isChecked: boolean,
    tableData: TableData,
    rowIdentifier?: string,
  ) => {
    if (!rowIdentifier) {
      return;
    }
    if (isChecked) {
      selectedRows.value = new Set(tableData.map((row) => row[rowIdentifier]));
    } else {
      selectedRows.value = new Set();
    }
    emitSelectedRows?.(selectedRows.value);
  };

  const handleRowSelection = (id: string | number, isChecked: boolean) => {
    if (isChecked) {
      selectedRows.value = new Set(selectedRows.value.add(id));
    } else {
      selectedRows.value.delete(id);
      selectedRows.value = new Set(selectedRows.value);
    }
    emitSelectedRows?.(selectedRows.value);
  };

  return {
    selectedRows,
    isSelectAllChecked,
    handleSelectAllRows,
    handleRowSelection,
  };
};
