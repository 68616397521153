import { defineStore } from 'pinia';

import type { GenericResponse } from '@/common/api';
import { postToken } from '@/common/api/postToken';
import { postTokenRefresh } from '@/common/api/postTokenRefresh';
import { FORBIDDEN, OK, UNAUTHORIZED } from '@/common/constants/status';
import { cookies } from '@/common/cookies';
import { handlePageReload } from '@/common/handlePageReload';
import router from '@/router';
import type { LoginOptions, TokenPayload } from '@/types';

import { useUserStore } from './user';

export interface AuthStoreState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  loginOptions: LoginOptions;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreState => ({
    accessToken: cookies.get('access_token'),
    refreshToken: cookies.get('refresh_token'),
    loginOptions: {},
  }),

  getters: {
    isAuthenticated: (state): boolean => {
      return !!state.accessToken && !!state.refreshToken;
    },
  },

  actions: {
    async obtainTokens(
      username: string,
      password: string,
    ): Promise<GenericResponse<TokenPayload> | null> {
      try {
        const response = await postToken(username, password);
        if (response.status === OK && response.data) {
          this.setTokens(response.data.access, response.data.refresh);
          return response;
        }
        console.debug('Unexpected error during token obtainment', response);

        return response;
      } catch (error) {
        console.debug('Failed to obtain tokens', error);
        return null;
      }
    },

    async doTokenRefresh() {
      console.debug('Attempting token refresh ...');
      if (!this.refreshToken) {
        console.debug('No refresh token available');
        this.logout();
        return;
      }

      try {
        const response = await postTokenRefresh(this.refreshToken);
        if (response.status === OK && response.data) {
          this.setAccessToken(response.data.access);
          console.debug('Token refreshed successfully!');
        } else if ([UNAUTHORIZED, FORBIDDEN].includes(response.status)) {
          this.logout();
        } else if (response.status === 409) {
          handlePageReload();
        } else {
          console.debug('Could not refresh token', response);
          this.logout();
        }
      } catch (error) {
        console.debug('Failed to refresh token', error);
        this.logout();
      }
    },

    setTokens(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      cookies.set('access_token', accessToken);
      cookies.set('refresh_token', refreshToken);
    },

    setAccessToken(accessToken: string) {
      this.accessToken = accessToken;
      cookies.set('access_token', accessToken);
    },

    setLoginOptions(options?: LoginOptions) {
      this.loginOptions = options || {};
    },

    clearTokens() {
      this.accessToken = undefined;
      this.refreshToken = undefined;
      cookies.unset('access_token');
      cookies.unset('refresh_token');
      console.debug('Tokens cleared');
    },

    getAccessToken() {
      return cookies.get('access_token');
    },

    logout() {
      this.clearTokens();
      localStorage.clear();
      sessionStorage.clear();
      useUserStore().clearUserData();
      router.push({ name: 'login' });
      console.debug('Logged out');
    },
  },
});
