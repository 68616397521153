import { onUnmounted } from 'vue';

function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  timeoutMs: number,
): [(args: A) => Promise<R>, () => void] {
  let timer: ReturnType<typeof setTimeout>;

  const debouncedFunction = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, timeoutMs);
    });

  const clearDebounce = () => clearTimeout(timer);

  return [debouncedFunction, clearDebounce];
}

export const useDebounce = <A = unknown, R = void>(
  fn: (args: A) => R,
  timeoutMs: number,
): ((args: A) => Promise<R>) => {
  const [debouncedFunction, clearDebounce] = debounce<A, R>(fn, timeoutMs);

  onUnmounted(clearDebounce);

  return debouncedFunction;
};
