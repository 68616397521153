<script setup lang="ts">
import { computed } from 'vue';
import clsx from 'clsx';
import { uniqueId } from '@/common/uniqueId';
import DeskLabel from '@/components/shared/DeskLabel/DeskLabel.vue';

const id = uniqueId('desk-checkbox');

interface Props {
  modelValue?: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
  dataTestId?: string;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit('update:modelValue', value);
  },
});

const classes = computed(() =>
  clsx('w-4.5 h-4.5 rounded-sm mt-1', {
    'bg-gray-100 border-gray-300': !props.disabled,
    'bg-gray-200 border-gray-200 cursor-not-allowed': props.disabled,
  }),
);
</script>

<template>
  <div class="flex items-start" :data-test-id="dataTestId || 'desk-checkbox'">
    <input :id="id" v-model="model" type="checkbox" :disabled="disabled" :class="classes" />
    <DeskLabel v-if="label" :for="id" class="ml-2" data-test-id="desk-textarea-label" :label="label"
      :description="description" />
  </div>
</template>
