// import { get } from '@/common/request'
import { makeURLWithItemFilters } from '@/common/api/getItems';
import { API_URLS } from '@/configs';
import type { CurrencyListPayload } from '@/types';

import { type GenericResponse, type GetItemsOptions, fetchData } from '.';

export type GetCurrenciesResponse = GenericResponse<CurrencyListPayload>;

export const getCurrencies = async (options: GetItemsOptions): Promise<GetCurrenciesResponse> => {
  const urlBase = new URL(`${API_URLS.BASE}/currencies/`);
  const url = makeURLWithItemFilters(urlBase, options);
  const getCurrenciesResponse = await fetchData(url);
  return getCurrenciesResponse;
};
