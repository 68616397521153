import { computed, type Ref } from 'vue';
import clsx from 'clsx';
import type { FlexContent, FlexDirection, FlexItems, Gap } from './types';

export type UseFlexboxClassesProps = {
  direction: Ref<FlexDirection>;
  gap: Ref<Gap>;
  display: Ref<'flex' | 'inline-flex'>;
  justifyContent: Ref<FlexContent>;
  alignItems: Ref<FlexItems>;
  alignSelf: Ref<FlexItems | 'auto'>;
  grow: Ref<'0' | '1'>;
};

export function useFlexboxClasses(props: UseFlexboxClassesProps): {
  flexboxClasses: Ref<string>;
} {
  const {
    direction,
    gap,
    display,
    justifyContent,
    alignItems,
    alignSelf,
    grow,
  } = props;
  const displayClass = display.value;
  const growClass = grow.value === '1' ? 'grow' : 'grow-0';

  const directionClassMap = {
    row: 'flex-row',
    'row-reverse': 'flex-row-reverse',
    column: 'flex-col',
    'column-reverse': 'flex-col-reverse',
  };
  const justifyContentClassMap = {
    start: 'justify-start',
    end: 'justify-end',
    'space-around': 'justify-around',
    'space-between': 'justify-between',
    'space-evenly': 'justify-evenly',
    normal: 'justify-normal',
    center: 'justify-center',
  };

  const alignItemsClassMap = {
    start: 'items-start',
    end: 'items-end',
    center: 'items-center',
    baseline: 'items-baseline',
    stretch: 'items-stretch',
  };
  const alignSelfClassMap = {
    start: 'self-start',
    end: 'self-end',
    center: 'self-center',
    baseline: 'self-baseline',
    stretch: 'self-stretch',
    auto: 'self-auto',
  };
  const gapClassMap = {
    '0': 'gap-0',
    '1': 'gap-1',
    '2': 'gap-2',
    '3': 'gap-3',
    '4': 'gap-4',
    '6': 'gap-6',
  };

  const directionClass: Ref<string> = computed(
    () => directionClassMap[direction.value],
  );
  const justifyContentClass: Ref<string> = computed(
    () => justifyContentClassMap[justifyContent.value],
  );
  const alignItemsClass: Ref<string> = computed(
    () => alignItemsClassMap[alignItems.value],
  );
  const alignSelfClass: Ref<string> = computed(
    () => alignSelfClassMap[alignSelf.value],
  );

  const gapClass: Ref<string> = computed(() => gapClassMap[gap.value]);

  const flexboxClasses = computed(() =>
    clsx(
      displayClass,
      directionClass.value,
      gapClass.value,
      justifyContentClass.value,
      alignItemsClass.value,
      alignSelfClass.value,
      growClass,
    ),
  );

  return {
    flexboxClasses,
  };
}
