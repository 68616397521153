<script setup lang="ts">
import { h, onMounted, ref, watch } from 'vue';

import { FhButton, FhIcon, FhLink } from '@fareharbor-com/beacon-vue';
import { location, search } from '@fareharbor-com/beacon-vue/icons';
import { storeToRefs } from 'pinia';

import SearchBar from '@/components/SearchBar/SearchBar.vue';
import { useLocationStore } from '@/stores/locations';
import { useSearchStore } from '@/stores/search';
import { useAuthStore } from '@/stores/useAuthStore';

import LocationSearchBar from './SearchBar/LocationSearchBar.vue';

const locationIcon = () => h(FhIcon, { icon: location });
const searchIcon = () => h(FhIcon, { icon: search });

const authStore = useAuthStore();

const { isAuthenticated } = storeToRefs(authStore);

const searchStore = useSearchStore();

const locationStore = useLocationStore();

// NOTE: To forcefully re-render the search bars the technique explained here is used:
// https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render
const searchBarKey = ref(0);

const doLogout = () => {
  authStore.logout();
};

function handleSubmitLocationSearch(text: string) {
  if (searchStore.locationSearchText === text) {
    searchStore.locationSearchChanged = false;
    return;
  }
  console.debug('[MainHeader] Search location:', text);
  searchStore.locationSearchText = text;
  searchStore.locationSearchChanged = true;
}

function handleSubmitCompanyItemSearch(text: string) {
  if (searchStore.companyItemSearchText === text) {
    searchStore.companyItemSearchChanged = false;
    return;
  }
  console.debug('[MainHeader] Search company / item:', text);
  searchStore.companyItemSearchText = text;
  searchStore.companyItemSearchChanged = true;
}

function handleClearLocationSearch() {
  console.debug('[MainHeader] Clearing location search');
  searchStore.locationSearchText = '';
  searchStore.locationSearchChanged = true;
}

function handleClearCompanyItemSearch() {
  console.debug('[MainHeader] Clearing company & item search');
  searchStore.companyItemSearchText = '';
  searchStore.companyItemSearchChanged = true;
}

function showLocationSuggestions() {
  const suggestions = locationStore.getSuggestions(searchStore.locationSearch);
  console.debug('[MainHeader] Show location suggestions: ', suggestions);
}

function handleSearchTextChange() {
  searchBarKey.value += 1;
  showLocationSuggestions();
}

// handleSearch is called when locationSearchText/companyItemSearchText is updated in searchStore.
searchStore.$subscribe(handleSearchTextChange);

watch(isAuthenticated, (newState) => {
  if (newState) {
    console.debug('[MainHeader][isAuthenticated-watch] Locations fetched');
    locationStore.fetchLocations();
  }
});

onMounted(() => {
  if (isAuthenticated.value) {
    console.debug('[MainHeader] Locations fetched');
    locationStore.fetchLocations();
  }
});
</script>

<template>
  <nav class="bg-white dark:bg-gray-900 border-b border-b-gray-300">
    <div class="flex flex-wrap justify-between items-center p-3">
      <RouterLink
        class="flex items-center space-x-3 rtl:space-x-reverse py-2"
        :to="{ name: 'item-list' }"
      >
        <img
          src="@/assets/images/logo.png"
          class="h-8 w-auto sm:h-10"
          alt="Logo"
          data-test-id="logo-icon"
        />
        <p
          class="text-xl"
          data-test-id="header-title"
        >
          Marketplace
        </p>
      </RouterLink>

      <div
        v-if="$route.name === 'item-list'"
        class="flex flex-col sm:flex-row sm:space-x-5 space-y-3 sm:space-y-0 w-full sm:w-auto"
        :key="searchBarKey"
      >
        <LocationSearchBar
          class="flex"
          :initialText="searchStore.locationSearchText"
          :placeholder="'Search by city, state, or country'"
          :icon="locationIcon"
          @submitSearch="handleSubmitLocationSearch"
          @clearSearch="handleClearLocationSearch"
        />
        <SearchBar
          class="flex"
          :initialText="searchStore.companyItemSearchText"
          :placeholder="'Search by company, shortname, item, or item ID'"
          :icon="searchIcon"
          @submitSearch="handleSubmitCompanyItemSearch"
          @clearSearch="handleClearCompanyItemSearch"
        />
      </div>

      <div class="flex items-center space-x-3 sm:space-x-6 rtl:space-x-reverse mt-3 sm:mt-0">
        <FhLink
          v-if="isAuthenticated"
          class="!no-underline"
          variant="primary"
          :href="'https://docs.google.com/forms/d/1AEHIjw1kETN5cOi1pnFR-3xlwxEU9X0rqaZnFGus9Is/edit'"
          target="_blank"
          data-test-id="user-feedback-link"
        >
          Feedback
        </FhLink>
        <FhButton
          v-if="isAuthenticated"
          variant="tertiary"
          @click="doLogout"
          data-test-id="logout-button"
        >
          Log out
        </FhButton>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
.auth-button {
  min-width: 2cm;

  svg {
    max-height: 1cm;
  }
}
</style>
