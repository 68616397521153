<script setup lang="ts">
import { computed, defineAsyncComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { FhButton, FhSpinner } from '@fareharbor-com/beacon-vue';
import { initFlowbite, initTooltips } from 'flowbite';

import { addItemToBookmarks } from '@/common/api/addItemToBookmarks';
import { deleteItemFromBookmarks } from '@/common/api/deleteItemFromBookmarks';
import { getItem } from '@/common/api/getItem';
import { getItemImages } from '@/common/api/getItemImages';
import { NOT_FOUND } from '@/common/constants/status';
import { markdownToHtml } from '@/common/markdownToHtml';
import ItemNotFound from '@/components/itemDetail/ItemNotFound.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useDebounce } from '@/composables/useDebounce';
import { transformSingleItemData } from '@/stores/items';
import { useUserStore } from '@/stores/user';
import type { Item } from '@/types';

const ImageGallery = defineAsyncComponent(() => import('@/components/itemDetail/ImageGallery.vue'));
const ItemSupplier = defineAsyncComponent(() => import('@/components/itemDetail/ItemSupplier.vue'));
const StructuredDescription = defineAsyncComponent(
  () => import('@/components/itemDetail/StructuredDescription.vue'),
);

const route = useRoute();
const userStore = useUserStore();

const isItemLoading = ref(true);
const isImagesLoading = ref(true);
const errorLoadingItem = ref(false);
const notFoundItem = ref(false);
const isAddingBookmarkLoading = ref(false);
const isBookmarked = ref(false);

const bookmarkError = ref<Error | null>(null);

const currentItem = ref<Item | null>(null);
const currentItemImages = ref<string[]>([]);

const isLoading = computed(() => isItemLoading.value || isImagesLoading.value);

const fetchItemDetail = async (itemPk: number) => {
  try {
    const { data, status, error } = await getItem(itemPk);
    if (error) {
      if (status === NOT_FOUND) {
        notFoundItem.value = true;
        errorLoadingItem.value = false;
        return;
      }
      console.error(`Unable to fetch item: ${error.message}`);
      errorLoadingItem.value = true;
      return;
    }

    const transformedItem = await transformSingleItemData(data);
    currentItem.value = transformedItem;
    isBookmarked.value = !!transformedItem.isBookmarked;

    await nextTick();
    initTooltips();
  } catch (e: unknown) {
    console.error(`An unexpected error occurred while fetching item: ${e}`);
    errorLoadingItem.value = true;
  } finally {
    isItemLoading.value = false;
  }
};

const fetchItemImages = async (itemPk: number) => {
  isImagesLoading.value = true;
  try {
    const { data: imageData = [], error: imageError } = await getItemImages(itemPk);
    if (imageError) {
      console.error(`Unable to fetch item images: ${imageError.message}`);
      return;
    }
    currentItemImages.value = imageData.map((image) => image.image_url);
  } catch (e: unknown) {
    console.error(`An unexpected error occurred while fetching images: ${e}`);
  } finally {
    isImagesLoading.value = false;
  }
};

const handleAddItemToBookmark = async () => {
  if (!currentItem.value) return;

  isAddingBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    const response = await addItemToBookmarks(currentItem.value.itemId);

    if (response.error) {
      bookmarkError.value = response.error;
      console.error('Error adding bookmark:', response.error);
    } else {
      console.log('Bookmark added:', response.data);
      isBookmarked.value = true;
    }
  } catch (e: unknown) {
    console.error('An unexpected error occurred while adding bookmark:', e);
    bookmarkError.value = e as Error;
  } finally {
    isAddingBookmarkLoading.value = false;
  }
};

const handleRemoveItemFromBookmark = async () => {
  if (!currentItem.value) return;

  isAddingBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    const response = await deleteItemFromBookmarks(currentItem.value.itemId);

    if (response.error) {
      bookmarkError.value = response.error;
      console.error('Error removing bookmark:', response.error);
    } else {
      console.log('Bookmark removed successfully');
      isBookmarked.value = false;
    }
  } catch (e: unknown) {
    console.error('An unexpected error occurred while removing bookmark:', e);
    bookmarkError.value = e as Error;
  } finally {
    isAddingBookmarkLoading.value = false;
  }
};

const loadItem = async () => {
  const { itemPk } = route.params;
  if (itemPk) {
    const itemPkNumber = Number(itemPk);

    isItemLoading.value = true;
    isImagesLoading.value = false;
    errorLoadingItem.value = false;
    notFoundItem.value = false;
    currentItem.value = null;
    currentItemImages.value = [];
    isBookmarked.value = false;

    await fetchItemDetail(itemPkNumber);

    if (!notFoundItem.value && !errorLoadingItem.value) {
      await fetchItemImages(itemPkNumber);
    }
  }
};

const getTransformedDescription = (item: Item): string | null => {
  return item.descriptionSafeHtml
    ? item.descriptionSafeHtml
    : markdownToHtml(item.description || '');
};

const debouncedLoadItem = useDebounce(loadItem, 300);

onMounted(async () => {
  await userStore.fetchUserData();
  await loadItem();
  initFlowbite();
});

watch(
  () => route.params.itemPk,
  (newItemPk) => {
    if (newItemPk) {
      debouncedLoadItem(newItemPk);
    }
  },
);
</script>

<template>
  <main class="container mx-auto px-4 pt-7 pb-36 flex-1 content-center">
    <template v-if="isLoading">
      <div class="flex items-center justify-center">
        <FhSpinner size="lg" />
      </div>
    </template>

    <template v-else-if="currentItem && !isLoading">
      <BaseFlexbox
        class="min-h-screen"
        direction="column"
      >
        <span class="text-xs font-normal text-gray-500">
          {{ currentItem.tags.map((tag) => tag.name.toUpperCase()).join(', ') }}
        </span>

        <span class="text-3xl font-medium mt-1">
          {{ currentItem.name }}
        </span>

        <span class="text-lg font-medium text-gray-400">
          {{ currentItem.headline }}
        </span>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-6">
          <BaseFlexbox
            direction="column"
            grow="1"
            gap="4"
          >
            <Suspense>
              <template #default>
                <ImageGallery
                  :images="currentItemImages"
                  :isLoading="isImagesLoading"
                />
              </template>
              <template #fallback>
                <div class="flex items-center justify-center h-32">
                  <FhSpinner size="sm" />
                </div>
              </template>
            </Suspense>
            <BaseFlexbox
              justify-content="end"
              class="pb-3 border-b border-gray-300"
            >
              <template v-if="!isBookmarked">
                <FhButton
                  size="sm"
                  @click="handleAddItemToBookmark"
                  :disabled="isAddingBookmarkLoading"
                >
                  <template v-if="isAddingBookmarkLoading">
                    <FhSpinner size="md" />
                    Saving
                  </template>
                  <template v-else>
                    <!-- Todo: Remove in [AF-1355]: Replace with beacon icons -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_5104_7902)">
                        <path
                          d="M15 10.8333V17.5L10 14.1667L5 17.5V5.83333C5 4.94928 5.35119 4.10143 5.97631 3.47631C6.60143 2.85119 7.44928 2.5 8.33333 2.5H10"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.3335 4.8335H17.3335"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.8335 2.3335V7.3335"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5104_7902">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Save item
                  </template>
                </FhButton>
              </template>
              <template v-else>
                <FhButton
                  size="sm"
                  @click="handleRemoveItemFromBookmark"
                  :disabled="isAddingBookmarkLoading"
                >
                  <template v-if="isAddingBookmarkLoading">
                    <FhSpinner size="md" />
                    Saving
                  </template>
                  <template v-else>
                    <!-- Todo: Remove in [AF-1355]: Replace with beacon icons -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_5168_1808)">
                        <path
                          d="M11.6667 1.6665C12.7718 1.6665 13.8316 2.10549 14.613 2.88689C15.3944 3.66829 15.8334 4.7281 15.8334 5.83317V17.4998C15.8334 17.6507 15.7925 17.7988 15.715 17.9282C15.6375 18.0576 15.5262 18.1636 15.3932 18.2347C15.2602 18.3059 15.1103 18.3396 14.9597 18.3323C14.809 18.325 14.6631 18.2769 14.5376 18.1932L10.0001 15.1682L5.46342 18.1932C5.34449 18.2728 5.20708 18.3204 5.06442 18.3315C4.92175 18.3425 4.77863 18.3167 4.64886 18.2564C4.51908 18.1961 4.40701 18.1034 4.32345 17.9873C4.23988 17.8711 4.18764 17.7354 4.17175 17.5932L4.16675 17.4998V5.83317C4.16675 4.7281 4.60573 3.66829 5.38714 2.88689C6.16854 2.10549 7.22835 1.6665 8.33342 1.6665H11.6667Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5168_1808">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Saved
                  </template>
                </FhButton>
              </template>
            </BaseFlexbox>
            <template v-if="currentItem.structuredDescription">
              <StructuredDescription
                :itemDescription="currentItem.structuredDescription"
                :item="currentItem"
              />
            </template>
            <template v-else>
              <BaseFlexbox
                class="mt-3 mb-6"
                direction="column"
              >
                <div
                  class="prose"
                  v-html="getTransformedDescription(currentItem)"
                />
              </BaseFlexbox>
            </template>
          </BaseFlexbox>

          <BaseFlexbox
            direction="column"
            grow="1"
          >
            <Suspense>
              <template #default>
                <ItemSupplier
                  :name="currentItem.company.name"
                  :shortname="currentItem.company.shortName"
                  :companyId="currentItem.company.id.toString()"
                  :companyUrl="currentItem.company.companyUrl"
                  :location="currentItem.company.primaryLocation"
                  :supplierItemCount="currentItem.companyItemCount"
                  :itemId="currentItem.itemId"
                  :companyImage="currentItem.company.companyImage"
                  :inFhdnApi="userStore.user?.inFhdnApi"
                  :inFhdn="userStore.user?.inFhdn"
                  :isStaff="userStore.user?.user.isStaff"
                  :companyCurrency="currentItem.company.currency"
                  :companyShortName="userStore.user?.company?.shortName"
                />
              </template>
              <template #fallback>
                <div class="flex items-center justify-center h-32">
                  <FhSpinner size="sm" />
                </div>
              </template>
            </Suspense>
          </BaseFlexbox>
        </div>
      </BaseFlexbox>
    </template>

    <template v-else-if="notFoundItem">
      <ItemNotFound />
    </template>

    <template v-else-if="errorLoadingItem">
      <ItemError @retry="loadItem" />
    </template>
  </main>
</template>
