import { InventoryEnvironment } from '@/types/InventoryEnvironment';

/**
 * Type guard that checks if a given string is a valid InventoryEnvironment.
 * This function ensures that the input is one of the known environments
 * defined in the InventoryEnvironment enum.
 *
 * @param env - The environment string to check.
 * @returns True if the input is a valid InventoryEnvironment, otherwise false.
 */
function isValidInventoryEnvironment(env: string): env is InventoryEnvironment {
  const validEnvs: string[] = Object.values(InventoryEnvironment);
  return validEnvs.includes(env);
}

/**
 * Returns a valid InventoryEnvironment or throws an error.
 *
 * @param env - The environment string to validate.
 * @returns The corresponding InventoryEnvironment if valid.
 * @throws Error if the environment is invalid.
 */
export function parseInventoryEnvironment(env: string): InventoryEnvironment {
  if (isValidInventoryEnvironment(env)) {
    return env;
  }
  throw new Error(`Invalid inventory environment: ${env}`);
}
