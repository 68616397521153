import { format } from 'date-fns';

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/**
  * Returns the date as a string formatted as "mm/dd/yy day"

  * @param inputDate - Date as a string formatted as "yyyy-mm-dd"
  * @returns Formatted date or null if date is not passed
  */
export const formatDateWithDay = (inputDate?: string | null) => {
  if (!inputDate) return null;

  const parts = inputDate.split('-');
  const year = parts[0].slice(-2);
  const month = parts[1];
  const day = parts[2];

  const parsedDate = new Date(inputDate);
  const dayOfWeek = dayNames[parsedDate.getUTCDay()];

  return `${month}/${day}/${year} ${dayOfWeek}`;
};

/**
  * Returns the date as a string formatted as "mm/dd/yy"

  * @param inputDate - Date as a string formatted as "yyyy-mm-dd"
  * @returns Formatted date or null if date is not passed
  */
export const formatDate = (inputDate?: string | null) => {
  if (!inputDate) return null;

  const parts = inputDate.split('-');
  const year = parts[0].slice(-2);
  const month = parts[1];
  const day = parts[2];

  return `${month}/${day}/${year}`;
};

/**
  * Returns the date as a string formatted as "yyyy-mm-dd"

  * @param inputDate - Date as a string formatted as Date object"
  * @returns Formatted date
  */
export const formatDateToYYYYMMDD = (date: Date) => format(date, 'yyyy-MM-dd');

/**
  * Returns the time as a string formatted as "hh:mm AM"

  * @param timeString - Time as a string formatted as "hh-mm-ss"
  * @returns Formatted time
  */
export const formatTime = (timeString: string) => {
  const [hours, minutes] = timeString.split(':');
  let period = 'AM';
  let formattedHours = parseInt(hours, 10);
  if (formattedHours > 12) {
    period = 'PM';
    formattedHours -= 12;
  }
  const hourString =
    formattedHours <= 9 ? formattedHours.toString().padStart(2, '0') : formattedHours.toString();
  return `${hourString}:${minutes} ${period}`;
};

/**
 * Formats date as time for given timezone.
 * Example return "hh:mm AM"
 * @param date
 * @param timeZone
 * @returns time formatted as "hh:mm AM" in given timeZone
 */
export const formatTimeToTimezone = (date: Date, timeZone: string): string => {
  const options = {
    timeZone,
  };
  const formattedDate = date.toLocaleString(undefined, options);
  const [, time] = formattedDate.split(', ');
  return formatTime(time);
};

/**
 * Returns the date as a string formatted as "mm/dd/yy"
 * @param datetime - ISO datetime string `2013-08-03T02:00:00Z`
 * @returns Formatted date
 */
export const getFormattedDate = (datetime: string) => {
  const date = new Date(datetime);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${month}/${day}/${year}`;
};

/**
 * Returns the time as a string formatted as "hh:mm AM/PM"
 * @param datetime - ISO datetime string `2013-08-03T02:00:00Z`
 * @returns Formatted time
 */
export const getFormattedTime = (datetime: string) => {
  const date = new Date(datetime);
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: 'numeric',
    hour12: true,
  });
};
