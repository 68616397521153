<script setup lang="ts">
import { type Component, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import { uniqueId } from '@/common/uniqueId';

interface SearchBarProps {
  initialText?: string;
  placeholder?: string;
  icon?: Component;
}

const props = defineProps<SearchBarProps>();
const emit = defineEmits(['submitSearch', 'clearSearch']);

const id = uniqueId('search-bar');
const text = ref(props.initialText || '');

const handleSubmit = () => {
  if (text.value.length > 0) {
    emit('submitSearch', text.value);
  } else {
    emit('clearSearch');
  }
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    handleSubmit();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});

watch(text, (newText) => {
  if (newText.length === 0) {
    emit('clearSearch');
  }
});
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="relative w-full sm:w-auto"
  >
    <div
      class="flex items-center sb-border-width focus-within:border-2 rounded-lg bg-gray-50 border-gray-300 focus-within:border-blue-500 w-full"
    >
      <Component
        :is="icon"
        class="w-6 h-6 ml-2 text-gray-500"
      />
      <input
        type="search"
        class="w-[26rem] bg-gray-50 placeholder-gray-400 rounded-lg border-transparent focus:border-transparent focus:ring-0"
        :id="id"
        :placeholder="props.placeholder"
        v-model="text"
        data-test-id="item-search-bar"
        @keydown.enter.prevent="handleSubmit"
      />
    </div>
  </form>
</template>

<style scoped>
.sb-border-width {
  border-width: 1px;
}
</style>
