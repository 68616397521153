export const replaceAll = (
  str: string | null | undefined,
  search: string,
  replacement: string,
): string => {
  const changedStr = str || '';
  // Escape special characters in the search string to treat it as a literal string
  const escapedSearch = search.replace(/[-^$*+?.()|[\]{}]/g, '\\$&');
  return changedStr.replace(new RegExp(escapedSearch, 'g'), replacement);
};
