<script setup lang="ts">
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskToastCloseButton from './DeskToastCloseButton.vue';
import DeskToastErrorIcon from './icons/DeskToastErrorIcon.vue';
import DeskToastLoadingIcon from './icons/DeskToastLoadingIcon.vue';
import DeskToastSuccessIcon from './icons/DeskToastSuccessIcon.vue';
import DeskToastWarningIcon from './icons/DeskToastWarningIcon.vue';
import type { ToastType } from './types';

// Dev reference
// https://medium.com/@serpentarium13/how-to-toast-on-your-own-in-vue-5962c0f954d1

interface ToastProps {
  type: ToastType;
  dismiss: () => void;
}

defineProps<ToastProps>();

const iconComponents = {
  success: DeskToastSuccessIcon,
  warning: DeskToastWarningIcon,
  error: DeskToastErrorIcon,
  loading: DeskToastLoadingIcon,
};
</script>

<template>
  <DeskFlexbox
    align-items="center"
    class="w-full p-3 mt-2 text-gray-500 bg-white rounded-lg shadow pointer-events-auto"
    role="toast"
  >
    <component
      v-if="type"
      :is="iconComponents[type]"
      data-test-id="desk-toast-icon"
    />
    <div class="ml-3" data-test-id="desk-toast-message">
      <slot></slot>
    </div>
    <DeskToastCloseButton @click="dismiss" data-test-id="desk-toast-close" />
  </DeskFlexbox>
</template>
