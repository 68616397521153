import mixpanel from 'mixpanel-browser';

import type { LoginTrackingEvent, TrackingService } from './TrackingService';

/**
 * A Mixpanel-based implementation of the `TrackingService` interface.
 *
 * This class integrates with the Mixpanel analytics service to track
 * user actions within the application.
 */
export class MixpanelTrackingService implements TrackingService {
  constructor(token: string, debug: boolean) {
    // TODO: Prevent reinitializing to avoid conflicts.
    mixpanel.init(token, { debug, track_pageview: false });
  }

  // eslint-disable-next-line class-methods-use-this
  public trackLogin(event: LoginTrackingEvent): void {
    mixpanel.register({ 'is-admin': event.isAdmin });
    mixpanel.track('login');
  }

  // eslint-disable-next-line class-methods-use-this
  public trackViewItemList(): void {
    mixpanel.track('view-item-list');
  }
}
