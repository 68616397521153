// cacheService.ts
class CacheService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  getItem<T>(key: string): T | null {
    const item = this.storage.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    }
    return null;
  }

  setItem<T>(key: string, value: T): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}

export const sessionCache = new CacheService(sessionStorage);
export const localCache = new CacheService(localStorage);
