import { inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { OK } from '@/common/constants/status';
import { formErrorParser } from '@/common/formErrorParser';
import type { TrackingService } from '@/common/tracking/TrackingService';
import { trackingKey } from '@/plugins/tracking';
import { useAuthStore } from '@/stores/useAuthStore';
import { useToastStore } from '@/stores/useToastStore';
import { useUserStore } from '@/stores/user';

export const useLogin = () => {
  const toastStore = useToastStore();
  const router = useRouter();
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const form = reactive({
    username: '',
    password: '',
  });

  const errors: string[] = reactive([]);
  const isLoading = ref(false);

  const tracking = inject<TrackingService>(trackingKey);

  const validateForm = () => {
    errors.splice(0, errors.length);

    if (!form.username || !form.password) {
      errors.push('username or password are required!');
    }
    return errors.length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      console.debug('Validation errors');
      return;
    }

    try {
      isLoading.value = true;

      const response: any = await authStore.obtainTokens(form.username, form.password);

      if (response.status === OK) {
        await userStore.fetchUserData();
        if (!userStore.user) {
          console.error('Logged in successfully but user data is missing');
        } else {
          console.debug(response.status, 'Logged in successfully as: ', userStore.user);
        }

        tracking?.trackLogin({
          isAdmin: userStore.user?.user.isStaff ?? false,
        });

        const { loginOptions } = authStore;
        if (loginOptions?.next) {
          router.push(loginOptions.next);
        } else {
          router.push({ name: 'item-list' });
        }
      } else {
        errors.push('Incorrect username or password.');
        const parsedErrors = formErrorParser(response.status, response.data);
        console.debug('Login failure:', parsedErrors);
      }
    } catch (error) {
      console.debug('Login error:', error);
      toastStore.error('A network error occurred. Please check your connection and try again.');
    } finally {
      isLoading.value = false;
    }
  };

  return {
    form,
    isLoading,
    errors,
    handleSubmit,
  };
};
