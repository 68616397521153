<script setup lang="ts" generic="TColumnKey extends string">
import type { Component } from 'vue';
import { useRouter } from 'vue-router';
// @ts-ignore
import { FhLink } from '@fareharbor-com/beacon-vue';

interface Props<TColumnKey extends string> {
  row: Record<TColumnKey, string | number> & { itemId?: number };
  value?: string | number;
  renderCell?: Component;
}

defineProps<Props<TColumnKey>>();

const router = useRouter();

function navigateToItem(event: Event, itemId: number | undefined) {
  event?.preventDefault();
  if (itemId !== undefined) {
    window.open(`/items/${itemId}`, '_blank');
  }
}
</script>

<template>
  <td v-if="renderCell">
    <FhLink
      variant="plain"
      class="cursor-pointer !no-underline h-[100px] w-full"
      :href="`/items/${(row as Record<string, number>).itemId}`"
      @click="navigateToItem($event, (row as Record<string, number>).itemId)"
      target="_blank"
    >
     <component :is="renderCell" :row="row" />
    </FhLink>
  </td>
  <td
    v-else
    class="whitespace-nowrap px-2 py-4 overflow-hidden"
    spellcheck="false"
  >
    <FhLink
      variant="plain"
      class="cursor-pointer !no-underline h-[100px]"
      href="`/items/${(row as Record<string, number>).itemId}`"
      @click="navigateToItem($event, (row as Record<string, number>).itemId)"
      _target="blank"
    >
      {{ value }}
    </FhLink>
  </td>
</template>
