<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="30"
    viewBox="0 0 90 11"
    fill="white"
    aria-label="Powered by Logo"
  >
    <path
      d="M2.60877 2.74415V4.6557H6.98708V6.45063H2.60877V9.92387H0.714966V0.949219H7.57778V2.74415H2.60877Z"
    />
    <path
      d="M14.5953 9.92478L13.8879 8.23126H10.6226L9.91527 9.92478H8.18372L11.4947 2.32422H13.0615L16.3724 9.92478H14.5953ZM12.2578 4.31943L11.231 6.76591H13.282L12.2578 4.31943Z"
    />
    <path
      d="M22.9133 9.9237L21.2578 7.50764H19.9243V9.9237H18.2257V2.37891H21.7573C23.5775 2.37891 24.6702 3.31693 24.6702 4.86848V4.8913C24.6702 6.1082 23.9984 6.87384 23.0147 7.2313L24.9009 9.92623H22.9133V9.9237ZM22.9463 4.95468C22.9463 4.24229 22.4392 3.87722 21.6102 3.87722H19.9218V6.04482H21.6432C22.4697 6.04482 22.9463 5.61384 22.9463 4.9775V4.95468Z"
    />
    <path
      d="M27.3042 9.92369V2.37891H33.1301V3.8544H28.9926V5.38567H32.6332V6.86116H28.9926V8.44567H33.1859V9.92369H27.3042Z"
    />
    <path
      d="M41.6562 9.92387V6.15908H37.9319V9.92387H36.1294V0.949219H37.9319V4.46049H41.6562V0.949219H43.5398V9.92387H41.6562Z"
    />
    <path
      d="M51.7284 9.92478L51.0211 8.23126H47.7558L47.0484 9.92478H45.3169L48.6279 2.32422H50.1946L53.5056 9.92478H51.7284ZM49.391 4.31943L48.3642 6.76591H50.4152L49.391 4.31943Z"
    />
    <path
      d="M60.0465 9.9237L58.391 7.50764H57.0575V9.9237H55.3589V2.37891H58.8904C60.7107 2.37891 61.8034 3.31693 61.8034 4.86848V4.8913C61.8034 6.1082 61.1316 6.87384 60.1479 7.2313L62.0341 9.92623H60.0465V9.9237ZM60.0795 4.95468C60.0795 4.24229 59.5724 3.87722 58.7434 3.87722H57.0549V6.04482H58.7764C59.6028 6.04482 60.0795 5.61384 60.0795 4.9775V4.95468Z"
    />
    <path
      d="M67.8904 9.92369H64.2169V2.37891H67.8017C69.3786 2.37891 70.3496 3.14454 70.3496 4.33102V4.3513C70.3496 5.20313 69.8857 5.67722 69.3355 5.97891C70.2279 6.31355 70.7806 6.8206 70.7806 7.83215V7.85496C70.7806 9.23412 69.6347 9.92369 67.8904 9.92369ZM68.6637 4.60989C68.6637 4.11553 68.2657 3.83412 67.5507 3.83412H65.8724V5.42877H67.4392C68.1896 5.42877 68.6637 5.19046 68.6637 4.63017V4.60989ZM69.0947 7.62679C69.0947 7.11975 68.7093 6.80792 67.8372 6.80792H65.8724V8.46848H67.8904C68.6409 8.46848 69.0921 8.20989 69.0921 7.64961V7.62679H69.0947Z"
    />
    <path
      d="M76.8169 10.054C74.4338 10.054 72.7251 8.31734 72.7251 6.17255V6.14974C72.7251 4.00495 74.4566 2.24805 76.8397 2.24805C79.2228 2.24805 80.9316 3.98467 80.9316 6.12946V6.15227C80.9316 8.29706 79.2 10.054 76.8169 10.054ZM79.1544 6.15227C79.1544 4.85931 78.1834 3.77931 76.8144 3.77931C75.4454 3.77931 74.4972 4.8365 74.4972 6.12946V6.15227C74.4972 7.44523 75.4682 8.52269 76.8372 8.52269C78.2062 8.52269 79.1544 7.46551 79.1544 6.17255V6.15227Z"
    />
    <path
      d="M88.015 9.92369L86.3595 7.50764H85.026V9.92369H83.3274V2.37891H86.8589C88.6792 2.37891 89.7719 3.31693 89.7719 4.86848V4.8913C89.7719 6.1082 89.1001 6.87384 88.1164 7.2313L90.0001 9.92369H88.015ZM88.048 4.95468C88.048 4.24229 87.5409 3.87722 86.7119 3.87722H85.0234V6.04482H86.7449C87.5713 6.04482 88.048 5.61384 88.048 4.9775V4.95468Z"
    />
  </svg>
</template>
