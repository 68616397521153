import { API_URLS } from '@/configs';
import type { ItemPayload } from '@/types';

import { type GenericResponse, fetchData } from '.';

type GetItemResponse = GenericResponse<ItemPayload>;

/**
 * Fetches an item by its primary key.
 *
 * @param itemPk - The primary key of the item to fetch.
 * @returns A promise that resolves to the item data or an error response.
 */
export const getItem = async (itemPk: number): Promise<GetItemResponse> => {
  if (!Number.isInteger(itemPk) || itemPk <= 0) {
    throw new Error('Invalid item primary key. It must be a positive integer.');
  }

  const url = new URL(`${API_URLS.BASE}/item/${itemPk}`);

  try {
    const getItemResponse = await fetchData(url);
    return getItemResponse;
  } catch (error) {
    console.error('Failed to fetch item:', error);
    throw new Error('Unable to fetch the item. Please try again later.');
  }
};
