import { createApp } from 'vue';

import { createPinia } from 'pinia';

import { initializeSentry } from '@/common/sentry/initializeSentry';
import tracking from '@/plugins/tracking';
import router from '@/router';
import { guardUnauthenticated } from '@/router/guards';
import { setRouteErrorHandler } from '@/router/setRouteErrorHandler';
import { useAuthStore } from '@/stores/useAuthStore';

import App from './App.vue';
import './assets/style/main.css';
import './assets/style/prose.css';
import './index.css';

setRouteErrorHandler(router);

const app = createApp(App);

app.use(createPinia());
app.use(tracking);
app.use(router);

// Initialize Sentry
initializeSentry(app);

const authStore = useAuthStore();

const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 5 - 10_000; // 4 minutes and 50 seconds

async function refreshToken(): Promise<void> {
  try {
    console.debug('Attempting to refresh access token...');
    await authStore.doTokenRefresh();
    console.debug('Token refresh successful!');
  } catch (error) {
    console.debug('Error refreshing token:', error);
  }
}

async function initializeApp() {
  if (guardUnauthenticated(router.currentRoute.value)) {
    await refreshToken();
    setInterval(refreshToken, TOKEN_REFRESH_INTERVAL);
  } else {
    authStore.logout();
  }

  app.mount('#app');
}

initializeApp();
