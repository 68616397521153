import type { RouteLocationNormalized } from 'vue-router';

import { useAuthStore } from '@/stores/useAuthStore';

export function guardUnauthenticated(to: RouteLocationNormalized) {
  console.debug('Running unauthentication guard ...');
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated) {
    console.debug('[guardUnauthenticated] Unauthenticated state detected.');
    authStore.setLoginOptions({ next: to });
    return { name: 'login' };
  }

  console.debug('[guardUnauthenticated] Passed.');
  return true;
}

export function guardBadPageQueryParam(to: RouteLocationNormalized) {
  console.debug('Running bad page query parameter guard ...');
  const queryPage = to.query.page;
  const INVALID_PAGE_MSG = `[guardBadPageQueryParam] Page '${queryPage}' is not valid`;

  const targetWithoutPage = { ...to, query: { ...to.query } };
  delete targetWithoutPage.query.page;

  if (queryPage !== undefined) {
    if (
      Array.isArray(queryPage) ||
      queryPage === null ||
      Number.isNaN(Number.parseInt(queryPage, 10))
    ) {
      console.debug(INVALID_PAGE_MSG);
      return targetWithoutPage;
    }
  }

  console.debug('[guardBadPageQueryParam] Passed.');
  return true;
}

export function guardEmptyQueryParams(to: RouteLocationNormalized) {
  console.debug('Running empty query parameters guard ...');

  const targetWithoutEmptyQueryParams = { ...to, query: { ...to.query } };
  let isAltered = false;

  Object.entries(to.query).forEach(([key, value]) => {
    if (value === '') {
      console.debug(`[guardEmptyQueryParams] detected empty query parameter: ${key}`);
      delete targetWithoutEmptyQueryParams.query[key];
      isAltered = true;
    }
  });

  if (isAltered) {
    return targetWithoutEmptyQueryParams;
  }

  console.debug('[guardEmptyQueryParams] Passed.');
  return true;
}
