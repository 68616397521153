import type { Router } from 'vue-router';

import { handlePageReload } from '@/common/handlePageReload';
import { config } from '@/configs';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const routeErrorHandler = (): void => {
  if (config.ENVIRONMENT === InventoryEnvironment.PRODUCTION) {
    handlePageReload();
  }
};

export const setRouteErrorHandler = (router: Router): void => {
  router.onError(routeErrorHandler);
};
