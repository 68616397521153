<script setup lang="ts" generic="TColumnKey extends string">
import DeskCheckbox from '@/components/shared/DeskCheckbox/DeskCheckbox.vue';
import DeskTableCell from '@/components/shared/DeskTable/DeskTableCell.vue';
import type { ColumnConfig, TableData } from './types';
import DeskSpinner from '@/components/shared/DeskSpinner.vue';

interface Props<TColumnKey extends string> {
  data: TableData;
  columnDefinitions: ColumnConfig<TColumnKey>[];
  noRowsMessage?: string;
  isSelectable?: boolean;
  selectedIds?: Set<string | number>;
  rowIdentifier?: string;
  isLoading?: boolean;
}

defineProps<Props<TColumnKey>>();

const emit = defineEmits<{
  select: [id: number | string, isChecked: boolean];
}>();

const handleRowSelect = (id: number | string, isChecked: boolean) => {
  emit('select', id, isChecked);
};
</script>

<template>
  <tbody>
    <tr v-if="data.length === 0 && !isLoading" class="border-b text-center">
      <td :colspan="columnDefinitions.length" class="py-5">
        {{ noRowsMessage || 'No data available' }}
      </td>
    </tr>
    <tr v-if="isLoading" class="border-b text-center">
      <td :colspan="columnDefinitions.length" class="py-5">
        <DeskSpinner class="text-gray-200 fill-blue-600 w-9 h-9" />
      </td>
    </tr>
    <tr
      v-for="(row, rowIndex) in data"
      :key="rowIdentifier ? row[rowIdentifier] : rowIndex"
      class="border hover:bg-gray-100 cursor-pointer"
      data-test-id="item-row-count"
    >
      <td
        v-if="isSelectable && rowIdentifier"
        data-test-id="desk-table-row-checkbox"
      >
        <DeskCheckbox
          class="whitespace-nowrap px-2 py-4 overflow-hidden"
          :model-value="selectedIds?.has(row[rowIdentifier])"
          @update:model-value="
            (isChecked) =>
              rowIdentifier && handleRowSelect(row[rowIdentifier], isChecked)
          "
        />
      </td>
      <DeskTableCell
        v-for="(column, colIndex) in columnDefinitions"
        :key="`col-${colIndex}`"
        :value="row[column.key]"
        :row="row"
        :renderCell="column.renderCell"
        :data-test-id="`cell-${rowIdentifier ? row[rowIdentifier] : rowIndex}-${colIndex}`"
      />
    </tr>
  </tbody>
</template>
