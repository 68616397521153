// import { get } from '@/common/request'
import { API_URLS } from '@/configs';
import type { TokenRefreshPayload, TokenRefreshPostPayload } from '@/types';

import type { GenericResponse } from '.';

type PostTokenRefreshResponse = GenericResponse<TokenRefreshPayload>;

export const postTokenRefresh = async (refresh: string): Promise<PostTokenRefreshResponse> => {
  const url = `${API_URLS.BASE}/token/refresh/`;

  const POST_DATA: TokenRefreshPostPayload = {
    refresh,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(POST_DATA),
  });

  let error: Error | null = null;
  if (!response.ok) {
    error = new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  const postTokenRefreshResponse: PostTokenRefreshResponse = {
    status: response.status,
    data,
    error,
  };

  return postTokenRefreshResponse;
};
