import { computed, ref } from 'vue';

import { UTCDate } from '@date-fns/utc';
import { subDays } from 'date-fns';
import { defineStore } from 'pinia';

import { OfferedSinceOptionVariant } from '@/components/itemList/types';

function determineDateForOfferedSinceVariant(
  offeredSinceVariant: OfferedSinceOptionVariant,
): UTCDate | null {
  const TODAY = new UTCDate();
  TODAY.setHours(0, 0, 0, 0); // midnight

  switch (Number(offeredSinceVariant)) {
    case OfferedSinceOptionVariant.LAST_7_DAYS:
      return subDays(TODAY, 7);

    case OfferedSinceOptionVariant.LAST_30_DAYS:
      return subDays(TODAY, 30);

    case OfferedSinceOptionVariant.LAST_90_DAYS:
      return subDays(TODAY, 90);

    default:
      return null;
  }
}

export const useItemFiltersStore = defineStore('itemFiltersStore', () => {
  // state
  const tags = ref(new Set<string>());
  const offeredSinceVariant = ref(OfferedSinceOptionVariant.NO_PREFERENCE);
  const currencies = ref(new Set<string>());
  const includePhotos = ref(true);

  // computed
  const offeredSince = computed(() =>
    determineDateForOfferedSinceVariant(offeredSinceVariant.value),
  );

  return { tags, offeredSinceVariant, currencies, includePhotos, offeredSince };
});
