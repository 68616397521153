<script setup lang="ts">
import { FhButton } from '@fareharbor-com/beacon-vue';

import DeskForm from '@/components/shared/DeskForm/DeskForm.vue';
import DeskInput from '@/components/shared/DeskInput/DeskInput.vue';

import { useLogin } from './useLogin';

const { form, isLoading, errors, handleSubmit } = useLogin();
</script>

<template>
  <DeskForm
    form-name="Login Form"
    submit-text="Login"
    :errors="errors"
    :is-submitting="isLoading"
    :is-valid="!!(form.username && form.password)"
    @submit.prevent="handleSubmit"
    data-test-id="login-form"
  >
    <DeskInput
      class="mb-3"
      type="text"
      placeholder="Username"
      v-model="form.username"
      autocomplete="username"
      dataTestId="login-username"
    />
    <DeskInput
      class="mb-2"
      type="password"
      placeholder="Password"
      v-model="form.password"
      autocomplete="current-password"
      dataTestId="login-password"
    />
    <template #form-actions>
      <FhButton
        :disabled="isLoading"
        :loading="isLoading"
        type="submit"
        fullWidth
        data-test-id="login-button"
        class="mt-2"
      >
        Login
      </FhButton>
    </template>
  </DeskForm>
</template>
