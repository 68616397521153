<script setup lang="ts">
import { FhBadge, FhButton, FhText } from '@fareharbor-com/beacon-vue';
import { close } from '@fareharbor-com/beacon-vue/icons';

import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';

interface AppliedFilterBadgeProps {
  text: string;
}

defineProps<AppliedFilterBadgeProps>();

const emit = defineEmits(['close']);
</script>
<template>
  <FhBadge
    class="w-auto pl-2 pr-1"
    data-test-id="applied-filter-badge"
  >
    <BaseFlexbox
      align-items="center"
      gap="1"
    >
      <FhText
        tag="span"
        variant="body-lg"
        weight="medium"
        color="link-default"
      >
        {{ text }}
      </FhText>
      <FhButton
        variant="tertiary"
        size="xs"
        @click="emit('close')"
        :icon="close"
      />
    </BaseFlexbox>
  </FhBadge>
</template>
