<script setup lang="ts">
import { computed } from 'vue';

interface DeskLabelProps {
  for?: string;
  label: string;
  description?: string;
}
const props = defineProps<DeskLabelProps>();

const tagName = computed(() => (props.for ? 'label' : 'div'));
</script>

<template>
  <component :is="tagName" :for="props.for" class="block mb-2 text-gray-900">
    {{ label }}
    <div v-if="description" class="text-sm font-normal text-gray-500">
      {{ description }}
    </div>
  </component>
</template>
