import { UTCDate } from '@date-fns/utc';

import { authorizationHeaders } from '@/common/authorizationHeaders';
import type { SortByOption } from '@/common/constants/items';
import { FORBIDDEN, NO_CONTENT, UNAUTHORIZED } from '@/common/constants/status';
import router from '@/router';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';

export const MAX_PAGE_SIZE: number = 100; // as defined in the backend pagination handler

export interface GenericResponse<DataType> {
  status: number;
  error?: ErrorResponse | ValidationErrorResponse;
  data?: DataType;
}

export interface GetItemsURLOptions {
  page?: number | null;
  pageSize?: number | null;
  sortBy?: SortByOption | null;
  tags?: Set<string> | null;
  offeredSince?: UTCDate | null;
  currencies?: Set<string> | null;
  includePhotos?: boolean | null;
  searchLocation?: string | null;
  search?: string | null;
  csv?: boolean | null;
}

export interface GetItemsOptions {
  page?: number | null;
  pageSize?: number | null;
  sortBy?: SortByOption | null;
  tags?: Set<string> | null;
  offeredSince?: UTCDate | null;
  currencies?: Set<string> | null;
  includePhotos?: boolean | null;
  searchLocation?: string | null;
  search?: string | null;
}

export function redirectToLoginIfUnauthorized(status: number) {
  if (status === UNAUTHORIZED || status === FORBIDDEN) {
    router.push({ name: 'login' });
  }
}

type FetchDataResponse = GenericResponse<any>;

/*
 * Generic function to fetch data as an authenticated user from the given URL.
 * It returns a GenericResponse object with the status, data and error.
 * It also redirects to the login page if the status is 401 or 403.
 */
export const fetchData = async (url: URL): Promise<FetchDataResponse> => {
  const response: Response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
  });

  let error: Error | null = null;
  if (!response.ok) {
    error = new Error(`HTTP error! status: ${response.status}`);
    redirectToLoginIfUnauthorized(response.status);
  }

  const data = response.status !== NO_CONTENT ? await response.json() : undefined;

  const fetchDataResponse: FetchDataResponse = {
    status: response.status,
    data,
    error,
  };

  return fetchDataResponse;
};
