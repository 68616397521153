import type { ItemListTableInstance } from '@/components/itemList/types';
import type { Item } from '@/types';

// @ts-ignore
export function transformItemsToTableData(items: Array<Item>): Array<ItemListTableInstance> {
  return items.map((item) => {
    return {
      itemId: item.itemId,
      name: item.name,
      companyName: item.company.name,
      companyShortName: item.company.shortName,
      imageUrl: item.mainImageUrl,
      itemType: item.tags,
      extraImagesCount: item.extraImageCount,
      city: item.location.city || '',
      region: item.location.province || '',
      countryCode: item.location.country || '',
      rating: item.rating,
      qualityScore: item.qualityScore,
    };
  });
}
