import { API_URLS } from '@/configs';
import type { MarketplaceUserPayload } from '@/types';

import { type GenericResponse, fetchData } from '.';

export type GetUserResponse = GenericResponse<MarketplaceUserPayload>;

export const getUser = async (): Promise<GetUserResponse> => {
  const url = new URL(`${API_URLS.BASE}/me/`);
  const getUserResponse = await fetchData(url);
  return getUserResponse;
};
