import { type Ref, ref } from 'vue';

import { defineStore } from 'pinia';

import { type GetItemsOptions, MAX_PAGE_SIZE } from '@/common/api';
import { getTags } from '@/common/api/getTags';
import type { Tag, TagListPayload, TagPayload } from '@/types';

async function transformSingleTagData(tagPayload: TagPayload): Promise<Tag> {
  return {
    name: tagPayload.name,
    shortname: tagPayload.shortname,
    relatedItemsCount: tagPayload.count,
  };
}

const transformTagsData = async (data: TagListPayload): Promise<Array<Tag>> => {
  return Promise.all(
    data.results.map(async (TagPayload) => {
      return transformSingleTagData(TagPayload);
    }),
  );
};

export const useTagsStore = defineStore('tagsStore', () => {
  const tags: Ref<Array<Tag>> = ref([]);
  const isReady: Ref<boolean> = ref(false);
  const isUpdated: Ref<boolean> = ref(false);

  /**
   * Fetch all pages of tags in batches of MAX_PAGE_SIZE
   */
  async function loadAllTags(options: GetItemsOptions = {}) {
    const MAX_REQUESTS_COUNT = 10;

    let newTags: Array<Tag> = [];
    isUpdated.value = false;
    if (tags.value.length === 0) {
      isReady.value = false;
    }

    let page = 1;
    let thereIsNext = true;
    while (thereIsNext && page <= MAX_REQUESTS_COUNT) {
      try {
        const fullOptions = { ...options, page, pageSize: MAX_PAGE_SIZE };
        // eslint-disable-next-line no-await-in-loop
        const { status, data } = await getTags(fullOptions);

        if (status !== 200 || !data) {
          throw new Error('Failed to load Tags');
        }

        // eslint-disable-next-line no-await-in-loop
        newTags = [...newTags, ...(await transformTagsData(data))];

        thereIsNext = data.next !== null;
        if (!thereIsNext) {
          console.debug('[stores][tags] No more pages to load', `current: ${page}`);
        } // no need to continue if no pages left
      } catch (e: unknown) {
        console.debug(`Unable to fetch tags: ${e}`);
        break;
      }
      page += 1;
    }

    // The counts might be updated, even when the list is the same.
    if (newTags.length === tags.value.length) {
      isUpdated.value = true;
    }
    tags.value = newTags;
    isReady.value = true;
  }

  return { tags, isReady, isUpdated, loadAllTags };
});
