<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { FwbPagination } from 'flowbite-vue';

import DeskSelect from '@/components/shared/DeskSelect/DeskSelect.vue';
import type { OptionsType } from '@/components/shared/DeskSelect/types';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';

interface ItemListPaginationProps {
  pageStart: number;
  pageEnd: number;
  totalItemsCount: number;
  showCount: boolean;

  showPagination: boolean;
  totalPagesCount: number;
  currentPage: number;

  currentPageSize: number | string;
  pageSizeOptions: OptionsType[];
}

const props = defineProps<ItemListPaginationProps>();
const emit = defineEmits<{
  pageChanged: [pageNumber: number];
  pageSizeChanged: [pageSize: number];
}>();

const showItemsCount = computed(() => props.showCount && props.totalItemsCount > 0);

const showPaginationComponent = computed(() => props.showPagination && props.totalPagesCount > 1);

const selectedPageSize = ref(props.currentPageSize);

watch(
  () => props.currentPageSize,
  (newVal) => {
    selectedPageSize.value = newVal;
  },
);

const pageSizeOptionsComputed = computed(() => {
  const options = [...props.pageSizeOptions]; // Use props.pageSizeOptions
  const selectedValue = selectedPageSize.value.toString(); // Use selectedPageSize

  const exists = options.some((option) => option.value.toString() === selectedValue);

  if (!exists) {
    options.push({
      id: selectedValue,
      name: selectedValue,
      value: selectedValue,
    });
  }

  options.sort((a, b) => Number(a.value) - Number(b.value));

  return options;
});
</script>
<template>
  <BaseFlexbox
    justifyContent="space-between"
    alignItems="center"
    class="w-full"
  >
    <!-- Left-aligned item count -->
    <BaseFlexbox
      alignItems="center"
      class="mx-2"
    >
      <div
        v-show="showItemsCount"
        class="flex-grow-0"
        data-test-id="item-count-info-footer"
      >
        Page
        <span class="font-bold">{{ pageStart }}-{{ pageEnd }}</span>
        of
        <span class="font-bold">{{ totalItemsCount }}</span>
      </div>

      <BaseFlexbox
        justifyContent="space-between"
        alignItems="center"
        class="mx-2"
      >
        Showing
        <DeskSelect
          v-model="selectedPageSize"
          :options="pageSizeOptionsComputed"
          @update:model-value="(value) => emit('pageSizeChanged', value)"
          dataTestId="current-page-size"
          class="px-2"
        />
        items
      </BaseFlexbox>
    </BaseFlexbox>

    <!-- Centered pagination -->
    <div class="flex justify-end flex-grow">
      <FwbPagination
        v-show="showPaginationComponent"
        show-icons
        :show-labels="false"
        :total-pages="totalPagesCount"
        :modelValue="currentPage"
        @pageChanged="emit('pageChanged', $event)"
        data-test-id="pagination-section"
      />
    </div>
  </BaseFlexbox>
</template>
