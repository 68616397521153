import { ref, type Ref } from 'vue';
import type { TableData } from './types';

export default function useSortableColumns<TColumnKey extends string>(
  tableData: TableData,
) {
  const sortDirection: Ref<1 | -1> = ref(1);
  const sortKey = ref('');

  const setSortDirection = (columnKey: TColumnKey) => {
    if (sortKey.value === columnKey) {
      // Table already sorted by this column. In that case, reverse the order.
      sortDirection.value *= -1;
    } else {
      sortKey.value = columnKey;
      // Set sorting order to default, ascending
      sortDirection.value = 1;
    }
  };

  const sortTableByColumn = (
    data: TableData,
    columnKey: TColumnKey,
    direction: 1 | -1,
  ): void => {
    data.sort((rowA, rowB) => {
      if (
        typeof rowA[columnKey] === 'number' &&
        typeof rowB[columnKey] === 'number'
      ) {
        return direction * (Number(rowA[columnKey]) - Number(rowB[columnKey]));
      }

      return (
        direction *
        String(rowA[columnKey]).localeCompare(String(rowB[columnKey]))
      );
    });
  };

  const sortByColumn = (columnKey: TColumnKey) => {
    setSortDirection(columnKey);
    sortTableByColumn(tableData, columnKey, sortDirection.value);
  };

  return { sortKey, sortDirection, sortByColumn };
}
