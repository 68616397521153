import { defineStore } from 'pinia';

import { getUser } from '@/common/api/getUser';
import type { MarketplaceUser, MarketplaceUserPayload } from '@/types';

export async function transformUserData(
  userPayload: MarketplaceUserPayload,
): Promise<MarketplaceUser> {
  try {
    const user = {
      user: {
        id: userPayload.user.id,
        username: userPayload.user.username,
        email: userPayload.user.email,
        firstName: userPayload.user.first_name,
        lastName: userPayload.user.last_name,
        isStaff: userPayload.user.is_staff,
        isActive: userPayload.user.is_active,
      },
      company: {
        id: userPayload.company.id,
        name: userPayload.company.name,
        shortName: userPayload.company.shortname,
        companyUrl: userPayload.company.company_url,
        currency: userPayload.company.currency,
      },
      createdAt: userPayload.created_at,
      modifiedAt: userPayload.modified_at,
      inFhdn: userPayload.in_fhdn,
      inFhdnApi: userPayload.in_fhdn_api,
    };
    return user;
  } catch (error) {
    console.debug('Error transforming user payload:', error);
    throw error;
  }
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as MarketplaceUser | null,
  }),

  actions: {
    async fetchUserData() {
      try {
        const response = await getUser();
        if (response.status !== 200) {
          console.error('Unexpected error fetching user data:', response);
          return;
        }
        if (!response.data) {
          console.error('No data found in response:', response);
          return;
        }
        console.log('Fetched user data:', response.data);
        this.user = await transformUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    clearUserData() {
      this.user = null;
    },
  },
});
