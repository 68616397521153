import type { App } from 'vue';

import { DummyTrackingService } from '@/common/tracking/DummyTrackingService';
import { MixpanelTrackingService } from '@/common/tracking/MixpanelTrackingService';
import type { TrackingService } from '@/common/tracking/TrackingService';
import { config } from '@/configs';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

export const trackingKey = Symbol('tracking');

type DebugByEnv = Record<InventoryEnvironment, boolean>;

const debugByEnv: DebugByEnv = {
  [InventoryEnvironment.PRODUCTION]: false,
  [InventoryEnvironment.STAGING]: true,
  [InventoryEnvironment.LOCAL]: true,
};

/**
 * Creates and returns an instance of the appropriate `TrackingService`
 * based on the environment configuration.
 *
 * This function checks if a Mixpanel token is available in the configuration:
 * - If a Mixpanel token is provided, it returns an instance of `MixpanelTrackingService`,
 *   using the token and environment-specific debug flag.
 * - If no token is available, it falls back to the `DummyTrackingService`, which serves
 *   as a no-op implementation of the tracking service that only logs the calls.
 *
 * @returns {TrackingService} An instance of `MixpanelTrackingService` or `DummyTrackingService`.
 */
function createService(): TrackingService {
  const debug = debugByEnv[config.ENVIRONMENT];
  const token = config.MIXPANEL_TOKEN;
  if (token) {
    return new MixpanelTrackingService(token, debug);
  }
  return new DummyTrackingService();
}

export default {
  install: (app: App) => {
    app.provide(trackingKey, createService());
  },
};
