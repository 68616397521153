import type { Component } from 'vue';
import clsx from 'clsx';

const baseInputClasses =
  'block w-full rounded-md sm:text-md focus:ring-blue-500';
const defaultInputClasses =
  'text-gray-900 border-gray-300 focus:border-blue-500 bg-gray-50';
const disabledInputClasses =
  'cursor-not-allowed bg-gray-100 text-gray-500 border-gray-300';
const errorInputClasses = 'border-red-500';

export type UseInputClassesProps = {
  disabled?: boolean;
  error?: boolean;
  inputStyles?: string;
  icon?: Component;
};

export const useInputClasses = () => {
  const getInputClasses = (props: UseInputClassesProps) =>
    clsx(baseInputClasses, props.inputStyles, {
      [defaultInputClasses]: !props.error && !props.disabled,
      [errorInputClasses]: props.error,
      [disabledInputClasses]: props.disabled,
      'pl-10': props.icon,
    });

  return {
    getInputClasses,
  };
};
