import { useRouter } from 'vue-router';

export function useQueryParams() {
  const router = useRouter();

  function getQueryParam(paramName: string): string | null {
    return router.currentRoute.value.query[paramName] as string | null;
  }

  function updateQueryParam(paramName: string, value: string) {
    router.push({
      ...router.currentRoute.value,
      query: {
        ...router.currentRoute.value.query,
        [paramName]: value,
      },
    });
  }

  function clearQueryParam(paramName: string) {
    const updatedQueryParams = { ...router.currentRoute.value.query };
    delete updatedQueryParams[paramName];
    router.push({
      ...router.currentRoute.value,
      query: updatedQueryParams,
    });
  }

  function updateArrayQueryParam(paramName: string, values: Set<string>) {
    let updatedQueryParams = { ...router.currentRoute.value.query };

    if (values.size > 0) {
      const stringifiedValues = Array.from(values).join(',');

      updatedQueryParams = {
        ...updatedQueryParams,
        [paramName]: stringifiedValues,
      };
    } else {
      delete updatedQueryParams[paramName];
    }
    const { currentRoute } = router;
    router.push({ ...currentRoute, query: updatedQueryParams });
  }

  function getArrayQueryParam(paramName: string): Set<string> {
    let result: Set<string> = new Set();

    const values = router.currentRoute.value.query[paramName];
    if (typeof values === 'string') {
      result = new Set(values.split(','));
    }

    return result;
  }

  function mentionQueryParam(paramName: string) {
    router.push({
      ...router.currentRoute.value,
      query: {
        ...router.currentRoute.value.query,
        [paramName]: null,
      },
    });
  }

  function isQueryParamMentioned(paramName: string): boolean {
    const { query } = router.currentRoute.value;
    return query === null ? false : paramName in query;
  }

  function getAllQueryParams(): QueryParams {
    return router.currentRoute.value.query as QueryParams;
  }

  function updateMultipleQueryParams(params: QueryParams) {
    const updatedQueryParams = { ...router.currentRoute.value.query };
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        delete updatedQueryParams[key];
      } else {
        updatedQueryParams[key] = value;
      }
    });
    router.push({
      ...router.currentRoute.value,
      query: updatedQueryParams,
    });
  }

  return {
    getQueryParam,
    updateQueryParam,
    clearQueryParam,
    updateArrayQueryParam,
    getArrayQueryParam,
    mentionQueryParam,
    isQueryParamMentioned,
    getAllQueryParams,
    updateMultipleQueryParams,
  };
}

export type QueryParams = {
  [key: string]: string | null;
};
