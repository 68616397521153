import { useAuthStore } from '@/stores/useAuthStore';

export const authorizationHeaders = () => {
  const authStore = useAuthStore();

  let headers = {};

  if (authStore.accessToken !== null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${authStore.accessToken}`,
    };
  }

  return headers;
};
